<template>
    <p>{{ message }}</p>

    <div style="margin-top: 40px;">
        <Button @click="reload" style="margin-left: auto; display: block"><b>Ок</b></Button>
    </div>
</template>

<script>
export default {
    name: 'AfterConvertDialog',
    inject: ['dialogRef'],
    data() {
        return {
            message: ''
        }
    },
    mounted() {
        const params = this.dialogRef.data;
        this.message = params.message;
    },
    methods: {
        reload() {
            window.location.reload();
        }
    }
}
</script>