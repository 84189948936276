<template>
  <div>
    <Card>
      <template #title>Блоки в событии</template>
      <template #content>
        <div class="grid">
          <div class="col-12 lg:col-3">
            <Card>
              <template #title>Выбор блока</template>
              <template #content>
                <Dropdown :filter="true" style="width: 100%; max-width: 100%" v-model="selectedBlock" :options="blocks" option-label="block"></Dropdown>
              </template>
            </Card>
          </div>
          <div class="col-12 lg:col-9">
            <Card>
              <template #title>Содержимое блока</template>
              <template #subtitle v-if="selectedBlock !== null">{{ selectedBlock.block }}</template>
              <template #content v-if="selectedBlock !== null">
                <p v-for="d in selectedBlock.data" :key="d.name"><strong>{{ d.name }}: </strong>{{ d.value }}</p>
              </template>
            </Card>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  name: "Blocks",
  props: {
    blocks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedBlock: null,
    }
  }
}
</script>

<style scoped>

</style>