<template>
  <div>
    <div v-if="loading">
      <div class="flex col-12 justify-content-center" style="position: absolute;top: 50%;transform: translate(0, -50%);">
        <ProgressSpinner></ProgressSpinner>
      </div>
    </div>
    <div v-if="!loading && error === null">
      <div class="grid">
        <div class="col-12 lg:col-4">
          <EventGeneral :event="event" :formats="formats"></EventGeneral>
        </div>
        <div class="col-12 lg:col-4">
          <Loan :loan="loan" v-if="loan !== null"></Loan>
        </div>
        <div class="col-12 lg:col-4">
          <Borrower :borrower="borrower"></Borrower>
        </div>
        <div class="col-12">
          <Blocks :blocks="event.blocks"></Blocks>
        </div>
        <div class="col-12">
          <ReportsTable :event-id="event.id"></ReportsTable>
        </div>
      </div>
    </div>
    <div v-if="!loading && error !== null">
      <Error :message="error"></Error>
    </div>
  </div>
</template>

<script>
import EventService from "@/service/EventService";
import ResponseService from "@/service/ResponseService";
import EventGeneral from "@/components/Event/EventGeneral";
import Loan from "@/components/Event/Loan";
import Borrower from "@/components/Event/Borrower";
import Blocks from "@/components/Event/Blocks";
import ReportsTable from "@/components/tables/ReportsTable";
import Error from "@/components/Error";

export default {
  name: "Event",
  components: {Error, ReportsTable, Blocks, Borrower, Loan, EventGeneral},
  data() {
    return {
      loading: true,
      error: null,
      event: {},
      formats: {},
      borrower: {},
      loan: {},
    }
  },
  mounted() {
    let eventId = this.$router.currentRoute.value.query.eventId ?? false
    let eventDate = this.$router.currentRoute.value.query.eventDate ?? false
    let locale = this.$router.currentRoute.value.query.locale ?? false
    if (!eventId || !eventDate || !locale) {
      this.$router.push('/')
      return
    }
    EventService.getEvent(eventId, eventDate, locale).then((res) => {
      this.event = res.event
      this.formats = res.formats
      this.borrower = res.borrower
      this.loan = res.loan
      this.loading = false
    }).catch((err) => {
      ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
      this.error = 'Ошибка получения данных'
      this.loading = false
    })
  }
}
</script>

<style scoped>

</style>