<template>
  <div>
    <Card>
      <template #title>Событие №{{ event.id }}</template>
      <template #content>
        <p><strong>ID: </strong>{{ event.id }}</p>
        <p><strong>Дата события: </strong>{{ event.eventDate }}</p>
        <p><strong>Дата создания: </strong>{{ dateService.dateToString(event.createdAt) }}</p>
        <p><strong>Временная зона: </strong>{{ event.locale }}</p>
        <Divider></Divider>
        <p><strong>Займ: </strong>{{ event.loanId }}</p>
        <p><strong>Заявка: </strong>{{ event.applicationUuid }}</p>
        <p><strong>Заёмщик: </strong>{{ event.borrowerUuid }}</p>
        <p><strong>ЦБ УИД: </strong>{{ event.cbUuid }}</p>
        <Divider></Divider>
        <p><strong>Версия формата Эквифакс: </strong>{{ formats.equ }}</p>
        <p><strong>Версия формата НБКИ: </strong>{{ formats.nbki }}</p>
        <p><strong>Тип: </strong>{{ compendiumService.getValueByCode($store.state.compendiums.eventType, event.type) }}</p>
        <p><strong>Тип отправки: </strong>{{ event.sendType }}</p>
      </template>
      <template #footer>
        <SplitButton label="Действия" icon="pi pi-bolt" :model="getActions()"></SplitButton>
      </template>
    </Card>
  </div>
</template>

<script>
import FilterDateService from "@/service/FilterDateService";
import CompendiumService from "@/service/CompendiumService";
import AfterConvertDialog from "@/components/Dialog/AfterConvertDialog.vue";
import EventActions from "@/helpers/actions/eventActions";

export default {
  name: "EventGeneral",
  props: {
    event: {
      type: Object,
      required: true
    },
    formats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateService: FilterDateService,
      compendiumService: CompendiumService,
    }
  },
  methods: {
    getActions(){
      const actions = new EventActions(this.event, this.formats);
      actions.afterDelete = this.backToEvents;
      actions.afterDeleteFromBureaus = this.backToEvents;
      actions.afterConvert = this.showAfterConvertDialog;
      return actions.getItems();
    },
    backToEvents(){
      this.$router.push('/');
    },
    showAfterConvertDialog(message) {
      this.$dialog.open(AfterConvertDialog, {
        data: {
          message
        },
        props: {
          header: 'Действие выполнено',
          modal: true,
          closable: false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>